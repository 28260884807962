export const lang ={
    usdt1:"What makes PayPal different?",
    usdt2:"Secure payment",
    usdt3:"Both buyers and sellers can enjoy industry-leading security protection.",
    usdt4:"User Trust",
    usdt5:"Enjoy a super-fast payment experience at millions of online stores around the world.",
    usdt6:"Charge Transparency",
    usdt7: "There is no registration fee or monthly fee, and the seller pays a handling fee based on the transaction amount.",
    usdt8:"USDT stored value card",
    usdt9: "Decentralized encryption technology based on KAD blockchain; KAD chain",
    usdt10:"Trusted by millions of users",
    usdt11:"Buy now",
    usdt12:"Compute node",
    usdt13: "Industry-leading node maintenance technology; superior hardware performance;",
    usdt14: "Manually on duty 7*24h.",
    usdt15:"Select hardware",
    usdt16:"virtual",
    usdt17:"entity",
    usdt18: "In addition to signing for the product in person, your family members or a third party designated by you will sign for it, or you instruct the carrier to place the product at the designated location. It will also be deemed that you have signed for it and the product has been completed.",
    usdt19:"OK",
    usdt20: "The current KAD price is 0, purchase is prohibited",
    usdt21: "No real-name authentication, please authenticate first",
    usdt22: "Real-name authentication pending review",
    usdt23: "Real-name authentication review failed",
    usdt24:"Order number",
    usdt25:"type",
    usdt26:"Shipping method",
    usdt27:"operation",
    usdt28:"mailbox",
    usdt29:"mail",
    usdt30:"checkout",
    usdt31:"What is your shipping address?",
    usdt32:"Enter your name and address",
    usdt33:"surname",
    usdt34:"name",
    usdt35:"Detailed address",
    usdt36:"Country/Region",
    usdt37:"Recipient's mobile phone number",
    usdt38:"Please enter your email address",
    usdt39:"Please note the following points:",
    usdt40: "If you choose a virtual card, please provide the correct email address and we will send it to your email.",
    usdt41: "The delivery cycle of physical cards is about one week. Please provide the correct delivery address and contact information.",
    usdt42: "Daytime delivery period: Monday to Sunday, 09:00 to 18:00 every day",
    usdt43: "In addition to your personal signature, the signature of your family members or a third party designated by you, or your instructing the carrier to place the product at the designated location will also be deemed to be your signature, and the product has been delivered. ",
    usdt44:"payment",
usdt45:"Select address",
usdt46:"OK",
usdt47:"Loading...",
usdt48:"Please complete the information",
usdt49: "USDT is being paid",
usdt50: "Payment successful, order is being generated",
usdt51:"Insufficient balance",
usdt52:"Payment failed",
usdt53:"Please select",
usdt54: "KAD is being paid",
usdt55: "Order generated successfully",
usdt56: "Order generation failed",
usdt57:"Country selection",
usdt58:"Search",
usdt59:"Personal Center",
usdt60:"Compute node order",
usdt61:"Stored value card order",
usdt62:"Real-name authentication",
usdt63:"To be reviewed",
usdt64:"to be authenticated",
usdt65: "Audit failed",
usdt66: "Audit successful",
usdt67:"Telegram community",
usdt68:"Real-name authentication",
usdt69:"Select a country and region",
usdt70:"Document type",
usdt71:"Passport",
usdt72:"real name",
usdt73:"Please enter your real name",
usdt74:"Certificate number",
usdt75:"Please enter the ID number",
usdt76: "ID photo (please choose a picture smaller than 2M)",
usdt77:"Please upload a face image",
usdt78:"Please upload the national emblem",
usdt79:"Please upload a photo of your hand",
usdt80:"Please select a country and region",
usdt81:"Please fill in your real name",
usdt82:"Please enter the ID number",
usdt83: "Submission successful, waiting for review",
usdt84:"Apply now",
usdt85:"Check email",
usdt86:"Check mobile phone number",
usdt87:"",
usdt88:"",
usdt89:"",
usdt90:"",
usdt91:"",
usdt92:"",
usdt93:"",
usdt94:"",
usdt95:"",
usdt96:"",
usdt97:"",
usdt98:"",
usdt99:"",
usdt100:"",

    
}