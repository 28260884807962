import request from '../utils/request';
export default {
    basicconfig() {
        
        return request.request({
            url: '/basic/config',
            method: "get",
           headers:{
            'Lang':localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
           }
            
        })
    },
    //注册用户
    register(data,account) {
        
        return request.request({
            url: '/register',
            method: "post",
            headers:{
                Authorization:account,
                'Lang':localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
            data:data
        })
    },
    cardlist(account) {
        
        return request.request({
            url: '/card/list',
            method: "get",
            headers:{
                Authorization:account,
                'Lang':localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
            
        })
    },
    ucauthemail(data,account) {
        
        return request.request({
            url: '/uc/auth/email',
            method: "post",
            headers:{
                Authorization:account,
                'Lang':localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
            data:data
        })
    },
    postimgfile(data,account){
        return request.request({
            url: '/upload/image',
            method: "post",
            headers:{
                "Accept":"*/*",
                "Access-Control-Allow-Origin":"*",
                Authorization:account,
                'Lang':localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
                "content-type": "multipart/form-data",
               
            },
            
           data:data,
         
        })
    },
    ucrealname(data,account) {
        
        return request.request({
            url: '/uc/auth/real-name',
            method: "post",
            headers:{
                Authorization:account,
                'Lang':localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
            data:data
        })
    },
    ucauthinfo(account) {
        
        return request.request({
            url: '/uc/auth/info',
            method: "get",
            headers:{
                Authorization:account,
                'Lang':localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
            
        })
    },
    cardconfig(account) {
        
        return request.request({
            url: '/card/config',
            method: "get",
            headers:{
                Authorization:account,
                'Lang':localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
            
        })
    },
    cardpaymark(data,account) {
        
        return request.request({
            url: '/card/pay/mark',
            method: "post",
            headers:{
                Authorization:account,
                'Lang':localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
            data:data
        })
    },
    cardholdlist(account) {
        
        return request.request({
            url: '/card/hold/list',
            method: "get",
            headers:{
                Authorization:account,
                'Lang':localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
            
        })
    },
}