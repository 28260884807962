import { createRouter, createWebHashHistory } from 'vue-router'


import fuwongIndex from '../views/fuwong/Index.vue'
import Cardlist from '../views/fuwong/Cardlist.vue'
import Check from '../views/fuwong/check.vue'
import address from '../views/fuwong/address.vue'
import Info from '../views/fuwong/info.vue'
import CardOrder from '../views/fuwong/cardorder.vue'
import Verified from '../views/fuwong/Verified.vue'
import countiy from '../views/fuwong/countiy.vue'
const routes = [
  {
      path:'/',
   
      name: 'fuwongIndex',
      component: fuwongIndex,
  },
  {
    path:'/Cardlist',
 
    name: 'Cardlist',
    component: Cardlist,
},
{
  path:'/Check',

  name: 'Check',
  component: Check,
},
{
  path:'/address',

  name: 'address',
  component: address,
},
{
  path:'/Info',

  name: 'Info',
  component: Info,
},
{
  path:'/CardOrder',

  name: 'CardOrder',
  component: CardOrder,
},
{
  path:'/Verified',

  name: 'Verified',
  component: Verified,
},
{
  path:'/countiy',

  name: 'countiy',
  component: countiy,
},
]

const router = createRouter({
  mode:'hash',
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
