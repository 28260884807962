import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f2ea4188"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "title-box"
};
const _hoisted_2 = {
  class: "box-left"
};
const _hoisted_3 = {
  class: "box-right"
};
export default {
  __name: 'title',
  props: {
    titletext: {
      type: String
    },
    price: {
      type: Number
    },
    token: {
      type: String
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(__props.titletext), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(__props.token) + "   " + _toDisplayString(__props.price), 1)]);
    };
  }
};