export default [
    {
      country_name_en: "China",
      country_name_fr: "Chine (la)",
      alpha2: "CN",
      alpha3: "CHN",
      number: "156",
      translation: "中国",
      label: "中国  China",
    },
    {
      country_name_en: "United States of America (the)",
      country_name_fr: "États-Unis d'Amérique (les)",
      alpha2: "US",
      alpha3: "USA",
      number: "840",
      translation: "美国",
      label: "美国  United States of America (the)",
    },
    {
      country_name_en: "Thailand",
      country_name_fr: "Thaïlande (la)",
      alpha2: "TH",
      alpha3: "THA",
      number: "764",
      translation: "泰国",
      label: "泰国  Thailand",
    },
    {
      country_name_en: "Viet Nam",
      country_name_fr: "Viet Nam (le)",
      alpha2: "VN",
      alpha3: "VNM",
      number: "704",
      translation: "越南",
      label: "越南  Viet Nam",
    },
    {
      country_name_en: "Indonesia",
      country_name_fr: "Indonésie (l')",
      alpha2: "ID",
      alpha3: "IDN",
      number: "360",
      translation: "印度尼西亚",
      label: "印度尼西亚  Indonesia",
    },
    {
      country_name_en: "Malaysia",
      country_name_fr: "Malaisie (la)",
      alpha2: "MY",
      alpha3: "MYS",
      number: "458",
      translation: "马来西亚",
      label: "马来西亚  Malaysia",
    },
    {
      country_name_en: "Afghanistan",
      country_name_fr: "Afghanistan (l')",
      alpha2: "AF",
      alpha3: "AFG",
      number: "004",
      translation: "阿富汗",
      label: "阿富汗  Afghanistan",
    },
    {
      country_name_en: "Åland Islands",
      country_name_fr: "Åland(les Îles)",
      alpha2: "AX",
      alpha3: "ALA",
      number: "248",
      translation: "",
      label: "Åland Islands",
    },
    {
      country_name_en: "Albania",
      country_name_fr: "Albanie (l')",
      alpha2: "AL",
      alpha3: "ALB",
      number: "008",
      translation: "阿尔巴尼亚",
      label: "阿尔巴尼亚  Albania",
    },
    {
      country_name_en: "Algeria",
      country_name_fr: "Algérie (l')",
      alpha2: "DZ",
      alpha3: "DZA",
      number: "012",
      translation: "阿尔及利亚",
      label: "阿尔及利亚  Algeria",
    },
    {
      country_name_en: "American Samoa",
      country_name_fr: "Samoa américaines (les)",
      alpha2: "AS",
      alpha3: "ASM",
      number: "016",
      translation: "美属萨摩亚",
      label: "美属萨摩亚  American Samoa",
    },
    {
      country_name_en: "Andorra",
      country_name_fr: "Andorre (l')",
      alpha2: "AD",
      alpha3: "AND",
      number: "020",
      translation: "安道尔",
      label: "安道尔  Andorra",
    },
    {
      country_name_en: "Angola",
      country_name_fr: "Angola (l')",
      alpha2: "AO",
      alpha3: "AGO",
      number: "024",
      translation: "安哥拉",
      label: "安哥拉  Angola",
    },
    {
      country_name_en: "Anguilla",
      country_name_fr: "Anguilla",
      alpha2: "AI",
      alpha3: "AIA",
      number: "660",
      translation: "安圭拉",
      label: "安圭拉  Anguilla",
    },
    {
      country_name_en: "Antarctica",
      country_name_fr: "Antarctique (l')",
      alpha2: "AQ",
      alpha3: "ATA",
      number: "010",
      translation: "南极洲",
      label: "南极洲  Antarctica",
    },
    {
      country_name_en: "Antigua and Barbuda",
      country_name_fr: "Antigua-et-Barbuda",
      alpha2: "AG",
      alpha3: "ATG",
      number: "028",
      translation: "安提瓜和巴布达",
      label: "安提瓜和巴布达  Antigua and Barbuda",
    },
    {
      country_name_en: "Argentina",
      country_name_fr: "Argentine (l')",
      alpha2: "AR",
      alpha3: "ARG",
      number: "032",
      translation: "阿根廷",
      label: "阿根廷  Argentina",
    },
    {
      country_name_en: "Armenia",
      country_name_fr: "Arménie (l')",
      alpha2: "AM",
      alpha3: "ARM",
      number: "051",
      translation: "亚美尼亚",
      label: "亚美尼亚  Armenia",
    },
    {
      country_name_en: "Aruba",
      country_name_fr: "Aruba",
      alpha2: "AW",
      alpha3: "ABW",
      number: "533",
      translation: "阿鲁巴",
      label: "阿鲁巴  Aruba",
    },
    {
      country_name_en: "Australia",
      country_name_fr: "Australie (l')",
      alpha2: "AU",
      alpha3: "AUS",
      number: "036",
      translation: "澳大利亚",
      label: "澳大利亚  Australia",
    },
    {
      country_name_en: "Austria",
      country_name_fr: "Autriche (l')",
      alpha2: "AT",
      alpha3: "AUT",
      number: "040",
      translation: "奥地利",
      label: "奥地利  Austria",
    },
    {
      country_name_en: "Azerbaijan",
      country_name_fr: "Azerbaïdjan (l')",
      alpha2: "AZ",
      alpha3: "AZE",
      number: "031",
      translation: "阿塞拜疆",
      label: "阿塞拜疆  Azerbaijan",
    },
    {
      country_name_en: "Bahamas (the)",
      country_name_fr: "Bahamas (les)",
      alpha2: "BS",
      alpha3: "BHS",
      number: "044",
      translation: "巴哈马",
      label: "巴哈马  Bahamas (the)",
    },
    {
      country_name_en: "Bahrain",
      country_name_fr: "Bahreïn",
      alpha2: "BH",
      alpha3: "BHR",
      number: "048",
      translation: "巴林",
      label: "巴林  Bahrain",
    },
    {
      country_name_en: "Bangladesh",
      country_name_fr: "Bangladesh (le)",
      alpha2: "BD",
      alpha3: "BGD",
      number: "050",
      translation: "孟加拉国",
      label: "孟加拉国  Bangladesh",
    },
    {
      country_name_en: "Barbados",
      country_name_fr: "Barbade (la)",
      alpha2: "BB",
      alpha3: "BRB",
      number: "052",
      translation: "巴巴多斯",
      label: "巴巴多斯  Barbados",
    },
    {
      country_name_en: "Belarus",
      country_name_fr: "Bélarus (le)",
      alpha2: "BY",
      alpha3: "BLR",
      number: "112",
      translation: "白俄罗斯",
      label: "白俄罗斯  Belarus",
    },
    {
      country_name_en: "Belgium",
      country_name_fr: "Belgique (la)",
      alpha2: "BE",
      alpha3: "BEL",
      number: "056",
      translation: "比利时",
      label: "比利时  Belgium",
    },
    {
      country_name_en: "Belize",
      country_name_fr: "Belize (le)",
      alpha2: "BZ",
      alpha3: "BLZ",
      number: "084",
      translation: "伯利兹",
      label: "伯利兹  Belize",
    },
    {
      country_name_en: "Benin",
      country_name_fr: "Bénin (le)",
      alpha2: "BJ",
      alpha3: "BEN",
      number: "204",
      translation: "贝宁",
      label: "贝宁  Benin",
    },
    {
      country_name_en: "Bermuda",
      country_name_fr: "Bermudes (les)",
      alpha2: "BM",
      alpha3: "BMU",
      number: "060",
      translation: "百慕大",
      label: "百慕大  Bermuda",
    },
    {
      country_name_en: "Bhutan",
      country_name_fr: "Bhoutan (le)",
      alpha2: "BT",
      alpha3: "BTN",
      number: "064",
      translation: "不丹",
      label: "不丹  Bhutan",
    },
    {
      country_name_en: "Bolivia (Plurinational State of)",
      country_name_fr: "Bolivie (État plurinational de)",
      alpha2: "BO",
      alpha3: "BOL",
      number: "068",
      translation: "玻利维亚",
      label: "玻利维亚  Bolivia (Plurinational State of)",
    },
    {
      country_name_en: "Bonaire, Sint Eustatius and Saba",
      country_name_fr: "Bonaire, Saint-Eustache et Saba",
      alpha2: "BQ",
      alpha3: "BES",
      number: "535",
      translation: "",
      label: "Bonaire, Sint Eustatius and Saba",
    },
    {
      country_name_en: "Bosnia and Herzegovina",
      country_name_fr: "Bosnie-Herzégovine (la)",
      alpha2: "BA",
      alpha3: "BIH",
      number: "070",
      translation: "波斯尼亚和黑塞哥维那的",
      label: "波斯尼亚和黑塞哥维那的  Bosnia and Herzegovina",
    },
    {
      country_name_en: "Botswana",
      country_name_fr: "Botswana (le)",
      alpha2: "BW",
      alpha3: "BWA",
      number: "072",
      translation: "博茨瓦纳",
      label: "博茨瓦纳  Botswana",
    },
    {
      country_name_en: "Bouvet Island",
      country_name_fr: "Bouvet (l'Île)",
      alpha2: "BV",
      alpha3: "BVT",
      number: "074",
      translation: "布维岛",
      label: "布维岛  Bouvet Island",
    },
    {
      country_name_en: "Brazil",
      country_name_fr: "Brésil (le)",
      alpha2: "BR",
      alpha3: "BRA",
      number: "076",
      translation: "巴西",
      label: "巴西的  Brazil",
    },
    {
      country_name_en: "British Indian Ocean Territory (the)",
      country_name_fr: "Indien (le Territoire britannique de l'océan)",
      alpha2: "IO",
      alpha3: "IOT",
      number: "086",
      translation: "英属印度洋领土",
      label: "英属印度洋领土  British Indian Ocean Territory (the)",
    },
    {
      country_name_en: "Brunei Darussalam",
      country_name_fr: "Brunéi Darussalam (le)",
      alpha2: "BN",
      alpha3: "BRN",
      number: "096",
      translation: "文莱",
      label: "文莱  Brunei Darussalam",
    },
    {
      country_name_en: "Bulgaria",
      country_name_fr: "Bulgarie (la)",
      alpha2: "BG",
      alpha3: "BGR",
      number: "100",
      translation: "保加利亚",
      label: "保加利亚  Bulgaria",
    },
    {
      country_name_en: "Burkina Faso",
      country_name_fr: "Burkina Faso (le)",
      alpha2: "BF",
      alpha3: "BFA",
      number: "854",
      translation: "布基纳法索",
      label: "布基纳法索  Burkina Faso",
    },
    {
      country_name_en: "Burundi",
      country_name_fr: "Burundi (le)",
      alpha2: "BI",
      alpha3: "BDI",
      number: "108",
      translation: "布隆迪",
      label: "布隆迪  Burundi",
    },
    {
      country_name_en: "Cabo Verde",
      country_name_fr: "Cabo Verde",
      alpha2: "CV",
      alpha3: "CPV",
      number: "132",
      translation: "Cape Verde",
      label: "Cape Verde  Cabo Verde",
    },
    {
      country_name_en: "Cambodia",
      country_name_fr: "Cambodge (le)",
      alpha2: "KH",
      alpha3: "KHM",
      number: "116",
      translation: "柬埔寨",
      label: "柬埔寨  Cambodia",
    },
    {
      country_name_en: "Cameroon",
      country_name_fr: "Cameroun (le)",
      alpha2: "CM",
      alpha3: "CMR",
      number: "120",
      translation: "喀麦隆",
      label: "喀麦隆  Cameroon",
    },
    {
      country_name_en: "Canada",
      country_name_fr: "Canada (le)",
      alpha2: "CA",
      alpha3: "CAN",
      number: "124",
      translation: "加拿大",
      label: "加拿大  Canada",
    },
    {
      country_name_en: "Cayman Islands (the)",
      country_name_fr: "Caïmans (les Îles)",
      alpha2: "KY",
      alpha3: "CYM",
      number: "136",
      translation: "开曼群岛",
      label: "开曼群岛  Cayman Islands (the)",
    },
    {
      country_name_en: "Central African Republic (the)",
      country_name_fr: "République centrafricaine (la)",
      alpha2: "CF",
      alpha3: "CAF",
      number: "140",
      translation: "中非共和国",
      label: "中非共和国  Central African Republic (the)",
    },
    {
      country_name_en: "Chad",
      country_name_fr: "Tchad (le)",
      alpha2: "TD",
      alpha3: "TCD",
      number: "148",
      translation: "乍得",
      label: "乍得  Chad",
    },
    {
      country_name_en: "Chile",
      country_name_fr: "Chili (le)",
      alpha2: "CL",
      alpha3: "CHL",
      number: "152",
      translation: "智利",
      label: "智利  Chile",
    },
    {
      country_name_en: "Christmas Island",
      country_name_fr: "Christmas (l'Île)",
      alpha2: "CX",
      alpha3: "CXR",
      number: "162",
      translation: "圣诞岛",
      label: "圣诞岛  Christmas Island",
    },
    {
      country_name_en: "Cocos (Keeling) Islands (the)",
      country_name_fr: "Cocos (les Îles)/ Keeling (les Îles)",
      alpha2: "CC",
      alpha3: "CCK",
      number: "166",
      translation: "科科斯(基灵)群岛",
      label: "科科斯(基灵)群岛  Cocos (Keeling) Islands (the)",
    },
    {
      country_name_en: "Colombia",
      country_name_fr: "Colombie (la)",
      alpha2: "CO",
      alpha3: "COL",
      number: "170",
      translation: "哥伦比亚",
      label: "哥伦比亚  Colombia",
    },
    {
      country_name_en: "Comoros (the)",
      country_name_fr: "Comores (les)",
      alpha2: "KM",
      alpha3: "COM",
      number: "174",
      translation: "科摩罗",
      label: "科摩罗  Comoros (the)",
    },
    {
      country_name_en: "Congo (the Democratic Republic of the)",
      country_name_fr: "Congo (la République démocratique du)",
      alpha2: "CD",
      alpha3: "COD",
      number: "180",
      translation: "刚果民主共和国",
      label: "刚果民主共和国  Congo (the Democratic Republic of the)",
    },
    {
      country_name_en: "Congo (the)",
      country_name_fr: "Congo (le)",
      alpha2: "CG",
      alpha3: "COG",
      number: "178",
      translation: "刚果",
      label: "刚果  Congo (the)",
    },
    {
      country_name_en: "Cook Islands (the)",
      country_name_fr: "Cook (les Îles)",
      alpha2: "CK",
      alpha3: "COK",
      number: "184",
      translation: "库克群岛",
      label: "库克群岛  Cook Islands (the)",
    },
    {
      country_name_en: "Costa Rica",
      country_name_fr: "Costa Rica (le)",
      alpha2: "CR",
      alpha3: "CRI",
      number: "188",
      translation: "哥斯达黎加",
      label: "哥斯达黎加  Costa Rica",
    },
    {
      country_name_en: "Côte d'Ivoire",
      country_name_fr: "Côte d'Ivoire (la)",
      alpha2: "CI",
      alpha3: "CIV",
      number: "384",
      translation: "象牙海岸",
      label: "象牙海岸  Côte d'Ivoire",
    },
    {
      country_name_en: "Croatia",
      country_name_fr: "Croatie (la)",
      alpha2: "HR",
      alpha3: "HRV",
      number: "191",
      translation: "克罗地亚",
      label: "克罗地亚  Croatia",
    },
    {
      country_name_en: "Cuba",
      country_name_fr: "Cuba",
      alpha2: "CU",
      alpha3: "CUB",
      number: "192",
      translation: "古巴",
      label: "古巴  Cuba",
    },
    {
      country_name_en: "Curaçao",
      country_name_fr: "Curaçao",
      alpha2: "CW",
      alpha3: "CUW",
      number: "531",
      translation: "",
      label: "Curaçao",
    },
    {
      country_name_en: "Cyprus",
      country_name_fr: "Chypre",
      alpha2: "CY",
      alpha3: "CYP",
      number: "196",
      translation: "塞浦路斯",
      label: "塞浦路斯  Cyprus",
    },
    {
      country_name_en: "Czechia",
      country_name_fr: "Tchéquie (la)",
      alpha2: "CZ",
      alpha3: "CZE",
      number: "203",
      translation: "捷克共和国",
      label: "捷克共和国  Czechia",
    },
    {
      country_name_en: "Denmark",
      country_name_fr: "Danemark (le)",
      alpha2: "DK",
      alpha3: "DNK",
      number: "208",
      translation: "丹麦",
      label: "丹麦  Denmark",
    },
    {
      country_name_en: "Djibouti",
      country_name_fr: "Djibouti",
      alpha2: "DJ",
      alpha3: "DJI",
      number: "262",
      translation: "吉布提",
      label: "吉布提  Djibouti",
    },
    {
      country_name_en: "Dominica",
      country_name_fr: "Dominique (la)",
      alpha2: "DM",
      alpha3: "DMA",
      number: "212",
      translation: "多米尼克",
      label: "多米尼克  Dominica",
    },
    {
      country_name_en: "Dominican Republic (the)",
      country_name_fr: "dominicaine (la République)",
      alpha2: "DO",
      alpha3: "DOM",
      number: "214",
      translation: "多米尼加共和国",
      label: "多米尼加共和国  Dominican Republic (the)",
    },
    {
      country_name_en: "Ecuador",
      country_name_fr: "Équateur (l')",
      alpha2: "EC",
      alpha3: "ECU",
      number: "218",
      translation: "厄瓜多尔",
      label: "厄瓜多尔  Ecuador",
    },
    {
      country_name_en: "Egypt",
      country_name_fr: "Égypte (l')",
      alpha2: "EG",
      alpha3: "EGY",
      number: "818",
      translation: "埃及",
      label: "埃及  Egypt",
    },
    {
      country_name_en: "El Salvador",
      country_name_fr: "El Salvador",
      alpha2: "SV",
      alpha3: "SLV",
      number: "222",
      translation: "萨尔瓦多",
      label: "萨尔瓦多  El Salvador",
    },
    {
      country_name_en: "Equatorial Guinea",
      country_name_fr: "Guinée équatoriale (la)",
      alpha2: "GQ",
      alpha3: "GNQ",
      number: "226",
      translation: "赤道几内亚",
      label: "赤道几内亚  Equatorial Guinea",
    },
    {
      country_name_en: "Eritrea",
      country_name_fr: "Érythrée (l')",
      alpha2: "ER",
      alpha3: "ERI",
      number: "232",
      translation: "厄立特里亚",
      label: "厄立特里亚  Eritrea",
    },
    {
      country_name_en: "Estonia",
      country_name_fr: "Estonie (l')",
      alpha2: "EE",
      alpha3: "EST",
      number: "233",
      translation: "爱沙尼亚",
      label: "爱沙尼亚  Estonia",
    },
    {
      country_name_en: "Eswatini",
      country_name_fr: "Eswatini (l')",
      alpha2: "SZ",
      alpha3: "SWZ",
      number: "748",
      translation: "斯威士兰",
      label: "斯威士兰  Eswatini",
    },
    {
      country_name_en: "Ethiopia",
      country_name_fr: "Éthiopie (l')",
      alpha2: "ET",
      alpha3: "ETH",
      number: "231",
      translation: "埃塞俄比亚",
      label: "埃塞俄比亚  Ethiopia",
    },
    {
      country_name_en: "Falkland Islands (the) [Malvinas]",
      country_name_fr: "Falkland (les Îles)/Malouines (les Îles)",
      alpha2: "FK",
      alpha3: "FLK",
      number: "238",
      translation: "福克兰群岛",
      label: "福克兰群岛  Falkland Islands (the) [Malvinas]",
    },
    {
      country_name_en: "Faroe Islands (the)",
      country_name_fr: "Féroé (les Îles)",
      alpha2: "FO",
      alpha3: "FRO",
      number: "234",
      translation: "法罗群岛",
      label: "法罗群岛  Faroe Islands (the)",
    },
    {
      country_name_en: "Fiji",
      country_name_fr: "Fidji (les)",
      alpha2: "FJ",
      alpha3: "FJI",
      number: "242",
      translation: "斐济群岛",
      label: "斐济群岛  Fiji",
    },
    {
      country_name_en: "Finland",
      country_name_fr: "Finlande (la)",
      alpha2: "FI",
      alpha3: "FIN",
      number: "246",
      translation: "芬兰",
      label: "芬兰  Finland",
    },
    {
      country_name_en: "France",
      country_name_fr: "France (la)",
      alpha2: "FR",
      alpha3: "FRA",
      number: "250",
      translation: "法国",
      label: "法国  France",
    },
    {
      country_name_en: "French Guiana",
      country_name_fr: "Guyane française (la )",
      alpha2: "GF",
      alpha3: "GUF",
      number: "254",
      translation: "法属圭亚那",
      label: "法属圭亚那  French Guiana",
    },
    {
      country_name_en: "French Polynesia",
      country_name_fr: "Polynésie française (la)",
      alpha2: "PF",
      alpha3: "PYF",
      number: "258",
      translation: "法属波利尼西亚",
      label: "法属波利尼西亚  French Polynesia",
    },
    {
      country_name_en: "French Southern Territories (the)",
      country_name_fr: "Terres australes françaises (les)",
      alpha2: "TF",
      alpha3: "ATF",
      number: "260",
      translation: "法国南部的领土",
      label: "法国南部的领土  French Southern Territories (the)",
    },
    {
      country_name_en: "Gabon",
      country_name_fr: "Gabon (le)",
      alpha2: "GA",
      alpha3: "GAB",
      number: "266",
      translation: "加蓬",
      label: "加蓬  Gabon",
    },
    {
      country_name_en: "Gambia (the)",
      country_name_fr: "Gambie (la)",
      alpha2: "GM",
      alpha3: "GMB",
      number: "270",
      translation: "冈比亚",
      label: "冈比亚  Gambia (the)",
    },
    {
      country_name_en: "Georgia",
      country_name_fr: "Géorgie (la)",
      alpha2: "GE",
      alpha3: "GEO",
      number: "268",
      translation: "格鲁吉亚",
      label: "格鲁吉亚  Georgia",
    },
    {
      country_name_en: "Germany",
      country_name_fr: "Allemagne (l')",
      alpha2: "DE",
      alpha3: "DEU",
      number: "276",
      translation: "德国",
      label: "德国  Germany",
    },
    {
      country_name_en: "Ghana",
      country_name_fr: "Ghana (le)",
      alpha2: "GH",
      alpha3: "GHA",
      number: "288",
      translation: "加纳",
      label: "加纳  Ghana",
    },
    {
      country_name_en: "Gibraltar",
      country_name_fr: "Gibraltar",
      alpha2: "GI",
      alpha3: "GIB",
      number: "292",
      translation: "直布罗陀",
      label: "直布罗陀  Gibraltar",
    },
    {
      country_name_en: "Greece",
      country_name_fr: "Grèce (la)",
      alpha2: "GR",
      alpha3: "GRC",
      number: "300",
      translation: "希腊",
      label: "希腊  Greece",
    },
    {
      country_name_en: "Greenland",
      country_name_fr: "Groenland (le)",
      alpha2: "GL",
      alpha3: "GRL",
      number: "304",
      translation: "格陵兰",
      label: "格陵兰  Greenland",
    },
    {
      country_name_en: "Grenada",
      country_name_fr: "Grenade (la)",
      alpha2: "GD",
      alpha3: "GRD",
      number: "308",
      translation: "格林纳达",
      label: "格林纳达  Grenada",
    },
    {
      country_name_en: "Guadeloupe",
      country_name_fr: "Guadeloupe (la)",
      alpha2: "GP",
      alpha3: "GLP",
      number: "312",
      translation: "瓜德罗普岛",
      label: "瓜德罗普岛  Guadeloupe",
    },
    {
      country_name_en: "Guam",
      country_name_fr: "Guam",
      alpha2: "GU",
      alpha3: "GUM",
      number: "316",
      translation: "关岛",
      label: "关岛  Guam",
    },
    {
      country_name_en: "Guatemala",
      country_name_fr: "Guatemala (le)",
      alpha2: "GT",
      alpha3: "GTM",
      number: "320",
      translation: "危地马拉",
      label: "危地马拉  Guatemala",
    },
    {
      country_name_en: "Guernsey",
      country_name_fr: "Guernesey",
      alpha2: "GG",
      alpha3: "GGY",
      number: "831",
      translation: "根西岛",
      label: "根西岛  Guernsey",
    },
    {
      country_name_en: "Guinea",
      country_name_fr: "Guinée (la)",
      alpha2: "GN",
      alpha3: "GIN",
      number: "324",
      translation: "几内亚",
      label: "几内亚  Guinea",
    },
    {
      country_name_en: "Guinea-Bissau",
      country_name_fr: "Guinée-Bissau (la)",
      alpha2: "GW",
      alpha3: "GNB",
      number: "624",
      translation: "几内亚比绍",
      label: "几内亚比绍  Guinea-Bissau",
    },
    {
      country_name_en: "Guyana",
      country_name_fr: "Guyana (le)",
      alpha2: "GY",
      alpha3: "GUY",
      number: "328",
      translation: "圭亚那",
      label: "圭亚那  Guyana",
    },
    {
      country_name_en: "Haiti",
      country_name_fr: "Haïti",
      alpha2: "HT",
      alpha3: "HTI",
      number: "332",
      translation: "海地",
      label: "海地  Haiti",
    },
    {
      country_name_en: "Heard Island and McDonald Islands",
      country_name_fr: "Heard-et-Îles MacDonald (l'Île)",
      alpha2: "HM",
      alpha3: "HMD",
      number: "334",
      translation: "赫德岛和麦克唐纳群岛",
      label: "赫德岛和麦克唐纳群岛  Heard Island and McDonald Islands",
    },
    {
      country_name_en: "Holy See (the)",
      country_name_fr: "Saint-Siège (le)",
      alpha2: "VA",
      alpha3: "VAT",
      number: "336",
      translation: "罗马教廷(梵蒂冈城市国家)",
      label: "罗马教廷(梵蒂冈城市国家)  Holy See (the)",
    },
    {
      country_name_en: "Honduras",
      country_name_fr: "Honduras (le)",
      alpha2: "HN",
      alpha3: "HND",
      number: "340",
      translation: "洪都拉斯",
      label: "洪都拉斯  Honduras",
    },
    {
      country_name_en: "Hong Kong",
      country_name_fr: "Hong Kong",
      alpha2: "HK",
      alpha3: "HKG",
      number: "344",
      translation: "",
      label: "Hong Kong",
    },
    {
      country_name_en: "Hungary",
      country_name_fr: "Hongrie (la)",
      alpha2: "HU",
      alpha3: "HUN",
      number: "348",
      translation: "匈牙利",
      label: "匈牙利  Hungary",
    },
    {
      country_name_en: "Iceland",
      country_name_fr: "Islande (l')",
      alpha2: "IS",
      alpha3: "ISL",
      number: "352",
      translation: "冰岛",
      label: "冰岛  Iceland",
    },
    {
      country_name_en: "India",
      country_name_fr: "Inde (l')",
      alpha2: "IN",
      alpha3: "IND",
      number: "356",
      translation: "印度",
      label: "印度  India",
    },
    {
      country_name_en: "Iran (Islamic Republic of)",
      country_name_fr: "Iran (République Islamique d')",
      alpha2: "IR",
      alpha3: "IRN",
      number: "364",
      translation: "伊朗",
      label: "伊朗  Iran (Islamic Republic of)",
    },
    {
      country_name_en: "Iraq",
      country_name_fr: "Iraq (l')",
      alpha2: "IQ",
      alpha3: "IRQ",
      number: "368",
      translation: "伊拉克",
      label: "伊拉克  Iraq",
    },
    {
      country_name_en: "Ireland",
      country_name_fr: "Irlande (l')",
      alpha2: "IE",
      alpha3: "IRL",
      number: "372",
      translation: "爱尔兰",
      label: "爱尔兰  Ireland",
    },
    {
      country_name_en: "Isle of Man",
      country_name_fr: "Île de Man",
      alpha2: "IM",
      alpha3: "IMN",
      number: "833",
      translation: "马恩岛",
      label: "马恩岛  Isle of Man",
    },
    {
      country_name_en: "Israel",
      country_name_fr: "Israël",
      alpha2: "IL",
      alpha3: "ISR",
      number: "376",
      translation: "以色列",
      label: "以色列  Israel",
    },
    {
      country_name_en: "Italy",
      country_name_fr: "Italie (l')",
      alpha2: "IT",
      alpha3: "ITA",
      number: "380",
      translation: "意大利",
      label: "意大利  Italy",
    },
    {
      country_name_en: "Jamaica",
      country_name_fr: "Jamaïque (la)",
      alpha2: "JM",
      alpha3: "JAM",
      number: "388",
      translation: "牙买加",
      label: "牙买加  Jamaica",
    },
    {
      country_name_en: "Japan",
      country_name_fr: "Japon (le)",
      alpha2: "JP",
      alpha3: "JPN",
      number: "392",
      translation: "日本",
      label: "日本  Japan",
    },
    {
      country_name_en: "Jersey",
      country_name_fr: "Jersey",
      alpha2: "JE",
      alpha3: "JEY",
      number: "832",
      translation: "泽西",
      label: "泽西  Jersey",
    },
    {
      country_name_en: "Jordan",
      country_name_fr: "Jordanie (la)",
      alpha2: "JO",
      alpha3: "JOR",
      number: "400",
      translation: "约旦",
      label: "约旦  Jordan",
    },
    {
      country_name_en: "Kazakhstan",
      country_name_fr: "Kazakhstan (le)",
      alpha2: "KZ",
      alpha3: "KAZ",
      number: "398",
      translation: "哈萨克斯坦",
      label: "哈萨克斯坦  Kazakhstan",
    },
    {
      country_name_en: "Kenya",
      country_name_fr: "Kenya (le)",
      alpha2: "KE",
      alpha3: "KEN",
      number: "404",
      translation: "肯尼亚",
      label: "肯尼亚  Kenya",
    },
    {
      country_name_en: "Kiribati",
      country_name_fr: "Kiribati",
      alpha2: "KI",
      alpha3: "KIR",
      number: "296",
      translation: "基里巴斯",
      label: "基里巴斯  Kiribati",
    },
    {
      country_name_en: "Korea (the Democratic People's Republic of)",
      country_name_fr: "Corée (la République populaire démocratique de)",
      alpha2: "KP",
      alpha3: "PRK",
      number: "408",
      translation: "北朝鲜",
      label: "北朝鲜  Korea (the Democratic People's Republic of)",
    },
    {
      country_name_en: "Korea (the Republic of)",
      country_name_fr: "Corée (la République de)",
      alpha2: "KR",
      alpha3: "KOR",
      number: "410",
      translation: "韩国",
      label: "韩国  Korea (the Republic of)",
    },
    {
      country_name_en: "Kuwait",
      country_name_fr: "Koweït (le)",
      alpha2: "KW",
      alpha3: "KWT",
      number: "414",
      translation: "科威特",
      label: "科威特  Kuwait",
    },
    {
      country_name_en: "Kyrgyzstan",
      country_name_fr: "Kirghizistan (le)",
      alpha2: "KG",
      alpha3: "KGZ",
      number: "417",
      translation: "吉尔吉斯斯坦",
      label: "吉尔吉斯斯坦  Kyrgyzstan",
    },
    {
      country_name_en: "Lao People's Democratic Republic (the)",
      country_name_fr: "Lao (la République démocratique populaire)",
      alpha2: "LA",
      alpha3: "LAO",
      number: "418",
      translation: "老挝",
      label: "老挝  Lao People's Democratic Republic (the)",
    },
    {
      country_name_en: "Latvia",
      country_name_fr: "Lettonie (la)",
      alpha2: "LV",
      alpha3: "LVA",
      number: "428",
      translation: "拉脱维亚",
      label: "拉脱维亚  Latvia",
    },
    {
      country_name_en: "Lebanon",
      country_name_fr: "Liban (le)",
      alpha2: "LB",
      alpha3: "LBN",
      number: "422",
      translation: "黎巴嫩",
      label: "黎巴嫩  Lebanon",
    },
    {
      country_name_en: "Lesotho",
      country_name_fr: "Lesotho (le)",
      alpha2: "LS",
      alpha3: "LSO",
      number: "426",
      translation: "莱索托",
      label: "莱索托  Lesotho",
    },
    {
      country_name_en: "Liberia",
      country_name_fr: "Libéria (le)",
      alpha2: "LR",
      alpha3: "LBR",
      number: "430",
      translation: "利比里亚",
      label: "利比里亚  Liberia",
    },
    {
      country_name_en: "Libya",
      country_name_fr: "Libye (la)",
      alpha2: "LY",
      alpha3: "LBY",
      number: "434",
      translation: "阿拉伯利比亚民众国",
      label: "阿拉伯利比亚民众国  Libya",
    },
    {
      country_name_en: "Liechtenstein",
      country_name_fr: "Liechtenstein (le)",
      alpha2: "LI",
      alpha3: "LIE",
      number: "438",
      translation: "列支敦士登",
      label: "列支敦士登  Liechtenstein",
    },
    {
      country_name_en: "Lithuania",
      country_name_fr: "Lituanie (la)",
      alpha2: "LT",
      alpha3: "LTU",
      number: "440",
      translation: "立陶宛",
      label: "立陶宛  Lithuania",
    },
    {
      country_name_en: "Luxembourg",
      country_name_fr: "Luxembourg (le)",
      alpha2: "LU",
      alpha3: "LUX",
      number: "442",
      translation: "卢森堡",
      label: "卢森堡  Luxembourg",
    },
    {
      country_name_en: "Macao",
      country_name_fr: "Macao",
      alpha2: "MO",
      alpha3: "MAC",
      number: "446",
      translation: "澳",
      label: "澳  Macao",
    },
    {
      country_name_en: "Madagascar",
      country_name_fr: "Madagascar",
      alpha2: "MG",
      alpha3: "MDG",
      number: "450",
      translation: "马达加斯加",
      label: "马达加斯加  Madagascar",
    },
    {
      country_name_en: "Malawi",
      country_name_fr: "Malawi (le)",
      alpha2: "MW",
      alpha3: "MWI",
      number: "454",
      translation: "马拉维",
      label: "马拉维  Malawi",
    },
    {
      country_name_en: "Maldives",
      country_name_fr: "Maldives (les)",
      alpha2: "MV",
      alpha3: "MDV",
      number: "462",
      translation: "马尔代夫",
      label: "马尔代夫  Maldives",
    },
    {
      country_name_en: "Mali",
      country_name_fr: "Mali (le)",
      alpha2: "ML",
      alpha3: "MLI",
      number: "466",
      translation: "马里",
      label: "马里  Mali",
    },
    {
      country_name_en: "Malta",
      country_name_fr: "Malte",
      alpha2: "MT",
      alpha3: "MLT",
      number: "470",
      translation: "马耳他",
      label: "马耳他  Malta",
    },
    {
      country_name_en: "Marshall Islands (the)",
      country_name_fr: "Marshall (les Îles)",
      alpha2: "MH",
      alpha3: "MHL",
      number: "584",
      translation: "马绍尔群岛",
      label: "马绍尔群岛  Marshall Islands (the)",
    },
    {
      country_name_en: "Martinique",
      country_name_fr: "Martinique (la)",
      alpha2: "MQ",
      alpha3: "MTQ",
      number: "474",
      translation: "马提尼克岛",
      label: "马提尼克岛  Martinique",
    },
    {
      country_name_en: "Mauritania",
      country_name_fr: "Mauritanie (la)",
      alpha2: "MR",
      alpha3: "MRT",
      number: "478",
      translation: "毛里塔尼亚",
      label: "毛里塔尼亚  Mauritania",
    },
    {
      country_name_en: "Mauritius",
      country_name_fr: "Maurice",
      alpha2: "MU",
      alpha3: "MUS",
      number: "480",
      translation: "毛里求斯",
      label: "毛里求斯  Mauritius",
    },
    {
      country_name_en: "Mayotte",
      country_name_fr: "Mayotte",
      alpha2: "YT",
      alpha3: "MYT",
      number: "175",
      translation: "马约特岛",
      label: "马约特岛  Mayotte",
    },
    {
      country_name_en: "Mexico",
      country_name_fr: "Mexique (le)",
      alpha2: "MX",
      alpha3: "MEX",
      number: "484",
      translation: "墨西哥",
      label: "墨西哥  Mexico",
    },
    {
      country_name_en: "Micronesia (Federated States of)",
      country_name_fr: "Micronésie (États fédérés de)",
      alpha2: "FM",
      alpha3: "FSM",
      number: "583",
      translation: "密克罗尼西亚联邦",
      label: "密克罗尼西亚联邦  Micronesia (Federated States of)",
    },
    {
      country_name_en: "Moldova (the Republic of)",
      country_name_fr: "Moldova (la République de)",
      alpha2: "MD",
      alpha3: "MDA",
      number: "498",
      translation: "摩尔多瓦",
      label: "摩尔多瓦  Moldova (the Republic of)",
    },
    {
      country_name_en: "Monaco",
      country_name_fr: "Monaco",
      alpha2: "MC",
      alpha3: "MCO",
      number: "492",
      translation: "摩纳哥",
      label: "摩纳哥  Monaco",
    },
    {
      country_name_en: "Mongolia",
      country_name_fr: "Mongolie (la)",
      alpha2: "MN",
      alpha3: "MNG",
      number: "496",
      translation: "蒙古",
      label: "蒙古  Mongolia",
    },
    {
      country_name_en: "Montenegro",
      country_name_fr: "Monténégro (le)",
      alpha2: "ME",
      alpha3: "MNE",
      number: "499",
      translation: "黑山",
      label: "黑山  Montenegro",
    },
    {
      country_name_en: "Montserrat",
      country_name_fr: "Montserrat",
      alpha2: "MS",
      alpha3: "MSR",
      number: "500",
      translation: "蒙特塞拉特",
      label: "蒙特塞拉特  Montserrat",
    },
    {
      country_name_en: "Morocco",
      country_name_fr: "Maroc (le)",
      alpha2: "MA",
      alpha3: "MAR",
      number: "504",
      translation: "摩洛哥",
      label: "摩洛哥  Morocco",
    },
    {
      country_name_en: "Mozambique",
      country_name_fr: "Mozambique (le)",
      alpha2: "MZ",
      alpha3: "MOZ",
      number: "508",
      translation: "莫桑比克",
      label: "莫桑比克  Mozambique",
    },
    {
      country_name_en: "Myanmar",
      country_name_fr: "Myanmar (le)",
      alpha2: "MM",
      alpha3: "MMR",
      number: "104",
      translation: "缅甸",
      label: "缅甸  Myanmar",
    },
    {
      country_name_en: "Namibia",
      country_name_fr: "Namibie (la)",
      alpha2: "NA",
      alpha3: "NAM",
      number: "516",
      translation: "纳米比亚",
      label: "纳米比亚  Namibia",
    },
    {
      country_name_en: "Nauru",
      country_name_fr: "Nauru",
      alpha2: "NR",
      alpha3: "NRU",
      number: "520",
      translation: "瑙鲁",
      label: "瑙鲁  Nauru",
    },
    {
      country_name_en: "Nepal",
      country_name_fr: "Népal (le)",
      alpha2: "NP",
      alpha3: "NPL",
      number: "524",
      translation: "尼泊尔",
      label: "尼泊尔  Nepal",
    },
    {
      country_name_en: "Netherlands (the)",
      country_name_fr: "Pays-Bas (les)",
      alpha2: "NL",
      alpha3: "NLD",
      number: "528",
      translation: "荷兰",
      label: "荷兰  Netherlands (the)",
    },
    {
      country_name_en: "New Caledonia",
      country_name_fr: "Nouvelle-Calédonie (la)",
      alpha2: "NC",
      alpha3: "NCL",
      number: "540",
      translation: "新喀里多尼亚",
      label: "新喀里多尼亚  New Caledonia",
    },
    {
      country_name_en: "New Zealand",
      country_name_fr: "Nouvelle-Zélande (la)",
      alpha2: "NZ",
      alpha3: "NZL",
      number: "554",
      translation: "新西兰",
      label: "新西兰  New Zealand",
    },
    {
      country_name_en: "Nicaragua",
      country_name_fr: "Nicaragua (le)",
      alpha2: "NI",
      alpha3: "NIC",
      number: "558",
      translation: "尼加拉瓜",
      label: "尼加拉瓜  Nicaragua",
    },
    {
      country_name_en: "Niger (the)",
      country_name_fr: "Niger (le)",
      alpha2: "NE",
      alpha3: "NER",
      number: "562",
      translation: "尼日尔",
      label: "尼日尔  Niger (the)",
    },
    {
      country_name_en: "Nigeria",
      country_name_fr: "Nigéria (le)",
      alpha2: "NG",
      alpha3: "NGA",
      number: "566",
      translation: "尼日利亚",
      label: "尼日利亚  Nigeria",
    },
    {
      country_name_en: "Niue",
      country_name_fr: "Niue",
      alpha2: "NU",
      alpha3: "NIU",
      number: "570",
      translation: "纽埃",
      label: "纽埃  Niue",
    },
    {
      country_name_en: "Norfolk Island",
      country_name_fr: "Norfolk (l'Île)",
      alpha2: "NF",
      alpha3: "NFK",
      number: "574",
      translation: "诺福克岛",
      label: "诺福克岛  Norfolk Island",
    },
    {
      country_name_en: "North Macedonia",
      country_name_fr: "Macédoine du Nord (la)",
      alpha2: "MK",
      alpha3: "MKD",
      number: "807",
      translation: "北的马其顿",
      label: "北的马其顿  North Macedonia",
    },
    {
      country_name_en: "Northern Mariana Islands (the)",
      country_name_fr: "Mariannes du Nord (les Îles)",
      alpha2: "MP",
      alpha3: "MNP",
      number: "580",
      translation: "北马里亚纳群岛",
      label: "北马里亚纳群岛  Northern Mariana Islands (the)",
    },
    {
      country_name_en: "Norway",
      country_name_fr: "Norvège (la)",
      alpha2: "NO",
      alpha3: "NOR",
      number: "578",
      translation: "挪威",
      label: "挪威  Norway",
    },
    {
      country_name_en: "Oman",
      country_name_fr: "Oman",
      alpha2: "OM",
      alpha3: "OMN",
      number: "512",
      translation: "阿曼",
      label: "阿曼  Oman",
    },
    {
      country_name_en: "Pakistan",
      country_name_fr: "Pakistan (le)",
      alpha2: "PK",
      alpha3: "PAK",
      number: "586",
      translation: "巴基斯坦",
      label: "巴基斯坦  Pakistan",
    },
    {
      country_name_en: "Palau",
      country_name_fr: "Palaos (les)",
      alpha2: "PW",
      alpha3: "PLW",
      number: "585",
      translation: "帕劳",
      label: "帕劳  Palau",
    },
    {
      country_name_en: "Palestine, State of",
      country_name_fr: "Palestine, État de",
      alpha2: "PS",
      alpha3: "PSE",
      number: "275",
      translation: "巴勒斯坦",
      label: "巴勒斯坦  Palestine, State of",
    },
    {
      country_name_en: "Panama",
      country_name_fr: "Panama (le)",
      alpha2: "PA",
      alpha3: "PAN",
      number: "591",
      translation: "巴拿马",
      label: "巴拿马  Panama",
    },
    {
      country_name_en: "Papua New Guinea",
      country_name_fr: "Papouasie-Nouvelle-Guinée (la)",
      alpha2: "PG",
      alpha3: "PNG",
      number: "598",
      translation: "巴布亚新几内亚",
      label: "巴布亚新几内亚  Papua New Guinea",
    },
    {
      country_name_en: "Paraguay",
      country_name_fr: "Paraguay (le)",
      alpha2: "PY",
      alpha3: "PRY",
      number: "600",
      translation: "巴拉圭",
      label: "巴拉圭  Paraguay",
    },
    {
      country_name_en: "Peru",
      country_name_fr: "Pérou (le)",
      alpha2: "PE",
      alpha3: "PER",
      number: "604",
      translation: "秘鲁",
      label: "秘鲁  Peru",
    },
    {
      country_name_en: "Philippines (the)",
      country_name_fr: "Philippines (les)",
      alpha2: "PH",
      alpha3: "PHL",
      number: "608",
      translation: "菲律宾",
      label: "菲律宾  Philippines (the)",
    },
    {
      country_name_en: "Pitcairn",
      country_name_fr: "Pitcairn",
      alpha2: "PN",
      alpha3: "PCN",
      number: "612",
      translation: "皮特凯恩",
      label: "皮特凯恩  Pitcairn",
    },
    {
      country_name_en: "Poland",
      country_name_fr: "Pologne (la)",
      alpha2: "PL",
      alpha3: "POL",
      number: "616",
      translation: "波兰",
      label: "波兰  Poland",
    },
    {
      country_name_en: "Portugal",
      country_name_fr: "Portugal (le)",
      alpha2: "PT",
      alpha3: "PRT",
      number: "620",
      translation: "葡萄牙",
      label: "葡萄牙  Portugal",
    },
    {
      country_name_en: "Puerto Rico",
      country_name_fr: "Porto Rico",
      alpha2: "PR",
      alpha3: "PRI",
      number: "630",
      translation: "波多黎各",
      label: "波多黎各  Puerto Rico",
    },
    {
      country_name_en: "Qatar",
      country_name_fr: "Qatar (le)",
      alpha2: "QA",
      alpha3: "QAT",
      number: "634",
      translation: "卡塔尔",
      label: "卡塔尔  Qatar",
    },
    {
      country_name_en: "Réunion",
      country_name_fr: "Réunion (La)",
      alpha2: "RE",
      alpha3: "REU",
      number: "638",
      translation: "留尼汪",
      label: "留尼汪  Réunion",
    },
    {
      country_name_en: "Romania",
      country_name_fr: "Roumanie (la)",
      alpha2: "RO",
      alpha3: "ROU",
      number: "642",
      translation: "罗马尼亚",
      label: "罗马尼亚  Romania",
    },
    {
      country_name_en: "Russian Federation (the)",
      country_name_fr: "Russie (la Fédération de)",
      alpha2: "RU",
      alpha3: "RUS",
      number: "643",
      translation: "俄罗斯联邦",
      label: "俄罗斯联邦  Russian Federation (the)",
    },
    {
      country_name_en: "Rwanda",
      country_name_fr: "Rwanda (le)",
      alpha2: "RW",
      alpha3: "RWA",
      number: "646",
      translation: "卢旺达问题",
      label: "卢旺达问题  Rwanda",
    },
    {
      country_name_en: "Saint Barthélemy",
      country_name_fr: "Saint-Barthélemy",
      alpha2: "BL",
      alpha3: "BLM",
      number: "652",
      translation: "",
      label: "Saint Barthélemy",
    },
    {
      country_name_en: "Saint Helena, Ascension and Tristan da Cunha",
      country_name_fr: "Sainte-Hélène, Ascension et Tristan da Cunha",
      alpha2: "SH",
      alpha3: "SHN",
      number: "654",
      translation: "圣赫勒拿的",
      label: "圣赫勒拿的  Saint Helena, Ascension and Tristan da Cunha",
    },
    {
      country_name_en: "Saint Kitts and Nevis",
      country_name_fr: "Saint-Kitts-et-Nevis",
      alpha2: "KN",
      alpha3: "KNA",
      number: "659",
      translation: "圣基茨和尼维斯",
      label: "圣基茨和尼维斯  Saint Kitts and Nevis",
    },
    {
      country_name_en: "Saint Lucia",
      country_name_fr: "Sainte-Lucie",
      alpha2: "LC",
      alpha3: "LCA",
      number: "662",
      translation: "圣卢西亚",
      label: "圣卢西亚  Saint Lucia",
    },
    {
      country_name_en: "Saint Martin (French part)",
      country_name_fr: "Saint-Martin (partie française)",
      alpha2: "MF",
      alpha3: "MAF",
      number: "663",
      translation: "",
      label: "Saint Martin (French part)",
    },
    {
      country_name_en: "Saint Pierre and Miquelon",
      country_name_fr: "Saint-Pierre-et-Miquelon",
      alpha2: "PM",
      alpha3: "SPM",
      number: "666",
      translation: "圣皮埃尔和密克隆群岛",
      label: "圣皮埃尔和密克隆群岛  Saint Pierre and Miquelon",
    },
    {
      country_name_en: "Saint Vincent and the Grenadines",
      country_name_fr: "Saint-Vincent-et-les Grenadines",
      alpha2: "VC",
      alpha3: "VCT",
      number: "670",
      translation: "圣文森特和格林纳丁斯",
      label: "圣文森特和格林纳丁斯  Saint Vincent and the Grenadines",
    },
    {
      country_name_en: "Samoa",
      country_name_fr: "Samoa (le)",
      alpha2: "WS",
      alpha3: "WSM",
      number: "882",
      translation: "萨摩亚",
      label: "萨摩亚  Samoa",
    },
    {
      country_name_en: "San Marino",
      country_name_fr: "Saint-Marin",
      alpha2: "SM",
      alpha3: "SMR",
      number: "674",
      translation: "圣马力诺",
      label: "圣马力诺  San Marino",
    },
    {
      country_name_en: "Sao Tome and Principe",
      country_name_fr: "Sao Tomé-et-Principe",
      alpha2: "ST",
      alpha3: "STP",
      number: "678",
      translation: "圣多美和普林西比",
      label: "圣多美和普林西比  Sao Tome and Principe",
    },
    {
      country_name_en: "Saudi Arabia",
      country_name_fr: "Arabie saoudite (l')",
      alpha2: "SA",
      alpha3: "SAU",
      number: "682",
      translation: "沙特阿拉伯",
      label: "沙特阿拉伯  Saudi Arabia",
    },
    {
      country_name_en: "Senegal",
      country_name_fr: "Sénégal (le)",
      alpha2: "SN",
      alpha3: "SEN",
      number: "686",
      translation: "塞内加尔",
      label: "塞内加尔  Senegal",
    },
    {
      country_name_en: "Serbia",
      country_name_fr: "Serbie (la)",
      alpha2: "RS",
      alpha3: "SRB",
      number: "688",
      translation: "塞尔维亚",
      label: "塞尔维亚  Serbia",
    },
    {
      country_name_en: "Seychelles",
      country_name_fr: "Seychelles (les)",
      alpha2: "SC",
      alpha3: "SYC",
      number: "690",
      translation: "塞舌尔",
      label: "塞舌尔  Seychelles",
    },
    {
      country_name_en: "Sierra Leone",
      country_name_fr: "Sierra Leone (la)",
      alpha2: "SL",
      alpha3: "SLE",
      number: "694",
      translation: "塞拉利昂",
      label: "塞拉利昂  Sierra Leone",
    },
    {
      country_name_en: "Singapore",
      country_name_fr: "Singapour",
      alpha2: "SG",
      alpha3: "SGP",
      number: "702",
      translation: "新加坡",
      label: "新加坡  Singapore",
    },
    {
      country_name_en: "Sint Maarten (Dutch part)",
      country_name_fr: "Saint-Martin (partie néerlandaise)",
      alpha2: "SX",
      alpha3: "SXM",
      number: "534",
      translation: "",
      label: "Sint Maarten (Dutch part)",
    },
    {
      country_name_en: "Slovakia",
      country_name_fr: "Slovaquie (la)",
      alpha2: "SK",
      alpha3: "SVK",
      number: "703",
      translation: "斯洛伐克",
      label: "斯洛伐克  Slovakia",
    },
    {
      country_name_en: "Slovenia",
      country_name_fr: "Slovénie (la)",
      alpha2: "SI",
      alpha3: "SVN",
      number: "705",
      translation: "斯洛文尼亚",
      label: "斯洛文尼亚  Slovenia",
    },
    {
      country_name_en: "Solomon Islands",
      country_name_fr: "Salomon (les Îles)",
      alpha2: "SB",
      alpha3: "SLB",
      number: "090",
      translation: "所罗门群岛",
      label: "所罗门群岛  Solomon Islands",
    },
    {
      country_name_en: "Somalia",
      country_name_fr: "Somalie (la)",
      alpha2: "SO",
      alpha3: "SOM",
      number: "706",
      translation: "索马里的",
      label: "索马里的  Somalia",
    },
    {
      country_name_en: "South Africa",
      country_name_fr: "Afrique du Sud (l')",
      alpha2: "ZA",
      alpha3: "ZAF",
      number: "710",
      translation: "南非",
      label: "南非  South Africa",
    },
    {
      country_name_en: "South Georgia and the South Sandwich Islands",
      country_name_fr: "Géorgie du Sud-et-les Îles Sandwich du Sud (la)",
      alpha2: "GS",
      alpha3: "SGS",
      number: "239",
      translation: "南乔治亚岛和南桑威奇群岛",
      label:
        "南乔治亚岛和南桑威奇群岛  South Georgia and the South Sandwich Islands",
    },
    {
      country_name_en: "South Sudan",
      country_name_fr: "Soudan du Sud (le)",
      alpha2: "SS",
      alpha3: "SSD",
      number: "728",
      translation: "苏丹南部",
      label: "苏丹南部  South Sudan",
    },
    {
      country_name_en: "Spain",
      country_name_fr: "Espagne (l')",
      alpha2: "ES",
      alpha3: "ESP",
      number: "724",
      translation: "西班牙",
      label: "西班牙  Spain",
    },
    {
      country_name_en: "Sri Lanka",
      country_name_fr: "Sri Lanka",
      alpha2: "LK",
      alpha3: "LKA",
      number: "144",
      translation: "斯里兰卡",
      label: "斯里兰卡  Sri Lanka",
    },
    {
      country_name_en: "Sudan (the)",
      country_name_fr: "Soudan (le)",
      alpha2: "SD",
      alpha3: "SDN",
      number: "729",
      translation: "苏丹",
      label: "苏丹  Sudan (the)",
    },
    {
      country_name_en: "Suriname",
      country_name_fr: "Suriname (le)",
      alpha2: "SR",
      alpha3: "SUR",
      number: "740",
      translation: "苏里南",
      label: "苏里南  Suriname",
    },
    {
      country_name_en: "Svalbard and Jan Mayen",
      country_name_fr: "Svalbard et l'Île Jan Mayen (le)",
      alpha2: "SJ",
      alpha3: "SJM",
      number: "744",
      translation: "斯瓦尔巴特群岛和扬马延岛",
      label: "斯瓦尔巴特群岛和扬马延岛  Svalbard and Jan Mayen",
    },
    {
      country_name_en: "Sweden",
      country_name_fr: "Suède (la)",
      alpha2: "SE",
      alpha3: "SWE",
      number: "752",
      translation: "瑞典",
      label: "瑞典  Sweden",
    },
    {
      country_name_en: "Switzerland",
      country_name_fr: "Suisse (la)",
      alpha2: "CH",
      alpha3: "CHE",
      number: "756",
      translation: "瑞士",
      label: "瑞士  Switzerland",
    },
    {
      country_name_en: "Syrian Arab Republic (the)",
      country_name_fr: "République arabe syrienne (la)",
      alpha2: "SY",
      alpha3: "SYR",
      number: "760",
      translation: "叙利亚",
      label: "叙利亚  Syrian Arab Republic (the)",
    },
    {
      country_name_en: "Taiwan (Province of China)",
      country_name_fr: "Taïwan (Province de Chine)",
      alpha2: "TW",
      alpha3: "TWN",
      number: "158",
      translation: "",
      label: "Taiwan (Province of China)",
    },
    {
      country_name_en: "Tajikistan",
      country_name_fr: "Tadjikistan (le)",
      alpha2: "TJ",
      alpha3: "TJK",
      number: "762",
      translation: "塔吉克斯坦",
      label: "塔吉克斯坦  Tajikistan",
    },
    {
      country_name_en: "Tanzania, the United Republic of",
      country_name_fr: "Tanzanie (la République-Unie de)",
      alpha2: "TZ",
      alpha3: "TZA",
      number: "834",
      translation: "坦桑尼亚",
      label: "坦桑尼亚  Tanzania, the United Republic of",
    },
    {
      country_name_en: "Timor-Leste",
      country_name_fr: "Timor-Leste (le)",
      alpha2: "TL",
      alpha3: "TLS",
      number: "626",
      translation: "东帝汶",
      label: "东帝汶  Timor-Leste",
    },
    {
      country_name_en: "Togo",
      country_name_fr: "Togo (le)",
      alpha2: "TG",
      alpha3: "TGO",
      number: "768",
      translation: "多哥",
      label: "多哥  Togo",
    },
    {
      country_name_en: "Tokelau",
      country_name_fr: "Tokelau (les)",
      alpha2: "TK",
      alpha3: "TKL",
      number: "772",
      translation: "托克劳",
      label: "托克劳  Tokelau",
    },
    {
      country_name_en: "Tonga",
      country_name_fr: "Tonga (les)",
      alpha2: "TO",
      alpha3: "TON",
      number: "776",
      translation: "汤加",
      label: "汤加  Tonga",
    },
    {
      country_name_en: "Trinidad and Tobago",
      country_name_fr: "Trinité-et-Tobago (la)",
      alpha2: "TT",
      alpha3: "TTO",
      number: "780",
      translation: "特立尼达和多巴哥",
      label: "特立尼达和多巴哥  Trinidad and Tobago",
    },
    {
      country_name_en: "Tunisia",
      country_name_fr: "Tunisie (la)",
      alpha2: "TN",
      alpha3: "TUN",
      number: "788",
      translation: "突尼斯",
      label: "突尼斯  Tunisia",
    },
    {
      country_name_en: "Turkey",
      country_name_fr: "Turquie (la)",
      alpha2: "TR",
      alpha3: "TUR",
      number: "792",
      translation: "土耳其",
      label: "土耳其  Turkey",
    },
    {
      country_name_en: "Turkmenistan",
      country_name_fr: "Turkménistan (le)",
      alpha2: "TM",
      alpha3: "TKM",
      number: "795",
      translation: "土库曼斯坦",
      label: "土库曼斯坦  Turkmenistan",
    },
    {
      country_name_en: "Turks and Caicos Islands (the)",
      country_name_fr: "Turks-et-Caïcos (les Îles)",
      alpha2: "TC",
      alpha3: "TCA",
      number: "796",
      translation: "特克斯和凯科斯群岛",
      label: "特克斯和凯科斯群岛  Turks and Caicos Islands (the)",
    },
    {
      country_name_en: "Tuvalu",
      country_name_fr: "Tuvalu (les)",
      alpha2: "TV",
      alpha3: "TUV",
      number: "798",
      translation: "图瓦卢",
      label: "图瓦卢  Tuvalu",
    },
    {
      country_name_en: "Uganda",
      country_name_fr: "Ouganda (l')",
      alpha2: "UG",
      alpha3: "UGA",
      number: "800",
      translation: "乌干达",
      label: "乌干达  Uganda",
    },
    {
      country_name_en: "Ukraine",
      country_name_fr: "Ukraine (l')",
      alpha2: "UA",
      alpha3: "UKR",
      number: "804",
      translation: "乌克兰",
      label: "乌克兰  Ukraine",
    },
    {
      country_name_en: "United Arab Emirates (the)",
      country_name_fr: "Émirats arabes unis (les)",
      alpha2: "AE",
      alpha3: "ARE",
      number: "784",
      translation: "阿拉伯联合酋长国",
      label: "阿拉伯联合酋长国  United Arab Emirates (the)",
    },
    {
      country_name_en:
        "United Kingdom of Great Britain and Northern Ireland (the)",
      country_name_fr: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord (le)",
      alpha2: "GB",
      alpha3: "GBR",
      number: "826",
      translation: "联合王国",
      label:
        "联合王国  United Kingdom of Great Britain and Northern Ireland (the)",
    },
    {
      country_name_en: "Uruguay",
      country_name_fr: "Uruguay (l')",
      alpha2: "UY",
      alpha3: "URY",
      number: "858",
      translation: "乌拉圭",
      label: "乌拉圭  Uruguay",
    },
    {
      country_name_en: "Uzbekistan",
      country_name_fr: "Ouzbékistan (l')",
      alpha2: "UZ",
      alpha3: "UZB",
      number: "860",
      translation: "乌兹别克斯坦",
      label: "乌兹别克斯坦  Uzbekistan",
    },
    {
      country_name_en: "Vanuatu",
      country_name_fr: "Vanuatu (le)",
      alpha2: "VU",
      alpha3: "VUT",
      number: "548",
      translation: "瓦努阿图",
      label: "瓦努阿图  Vanuatu",
    },
    {
      country_name_en: "Venezuela (Bolivarian Republic of)",
      country_name_fr: "Venezuela (République bolivarienne du)",
      alpha2: "VE",
      alpha3: "VEN",
      number: "862",
      translation: "委内瑞拉",
      label: "委内瑞拉  Venezuela (Bolivarian Republic of)",
    },
    {
      country_name_en: "Virgin Islands (British)",
      country_name_fr: "Vierges britanniques (les Îles)",
      alpha2: "VG",
      alpha3: "VGB",
      number: "092",
      translation: "属维尔京群岛、英国",
      label: "属维尔京群岛、英国  Virgin Islands (British)",
    },
    {
      country_name_en: "Virgin Islands (U.S.)",
      country_name_fr: "Vierges des États-Unis (les Îles)",
      alpha2: "VI",
      alpha3: "VIR",
      number: "850",
      translation: "属维尔京群岛、美国",
      label: "属维尔京群岛、美国  Virgin Islands (U.S.)",
    },
    {
      country_name_en: "Wallis and Futuna",
      country_name_fr: "Wallis-et-Futuna",
      alpha2: "WF",
      alpha3: "WLF",
      number: "876",
      translation: "瓦利斯群岛和富图纳群岛",
      label: "瓦利斯群岛和富图纳群岛  Wallis and Futuna",
    },
    {
      country_name_en: "Western Sahara*",
      country_name_fr: "Sahara occidental (le)*",
      alpha2: "EH",
      alpha3: "ESH",
      number: "732",
      translation: "西撒哈拉",
      label: "西撒哈拉  Western Sahara*",
    },
    {
      country_name_en: "Yemen",
      country_name_fr: "Yémen (le)",
      alpha2: "YE",
      alpha3: "YEM",
      number: "887",
      translation: "也门",
      label: "也门  Yemen",
    },
    {
      country_name_en: "Zambia",
      country_name_fr: "Zambie (la)",
      alpha2: "ZM",
      alpha3: "ZMB",
      number: "894",
      translation: "赞比亚",
      label: "赞比亚  Zambia",
    },
    {
      country_name_en: "Zimbabwe",
      country_name_fr: "Zimbabwe (le)",
      alpha2: "ZW",
      alpha3: "ZWE",
      number: "716",
      translation: "津巴布韦",
      label: "津巴布韦  Zimbabwe",
    },
  ];