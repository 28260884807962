export const lang ={
    usdt1:"是什麼讓PayPal與眾不同？",
    usdt2:"安全付款",
    usdt3:"無論買家或賣家，都可享有業界領先的安全保護。",
    usdt4:"用戶信賴",
    usdt5:"在全球數百萬網店享受超快速的支付體驗。",
    usdt6:"收費透明",
    usdt7:"沒有註冊費或月費，賣家按交易額支付手續費。",
    usdt8:"USDT儲值卡",
    usdt9:"基於KAD區塊鏈去中心化加密技術;KAD鏈",
    usdt10:"數百萬用戶信賴選擇",
    usdt11:"立即購買",
    usdt12:"計算節點",
    usdt13:"業界領先的節點維護技術;優越的硬體效能;",
    usdt14:"7*24h人工值守。",
    usdt15:"選擇硬體",
    usdt16:"虛擬",
    usdt17:"實體",
    usdt18:"除你本人親自簽收之外,你的同住家屬或你指定的第三方簽收,或你指示承運人將產品置於指定地點,亦將視為你本人簽收,且產品已完",
    usdt19:"確定",
    usdt20:"當前KAD價格為0,禁止購買",
    usdt21:"未實名認證，請先實名認證",
    usdt22:"實名認證待審核",
    usdt23:"實名認證審核失敗",
    usdt24:"訂單編號",
    usdt25:"型別",
    usdt26:"出貨方式",
    usdt27:"操作",
    usdt28:"信箱",
    usdt29:"郵遞",
    usdt30:"結帳",
    usdt31:"你的送貨地址是哪裡?",
    usdt32:"輸入你的姓名和地址",
    usdt33:"姓氏",
    usdt34:"名字",
    usdt35:"詳細地址",
    usdt36:"國家",
    usdt37:"收件人手機號碼",
    usdt38:"請輸入信箱",
    usdt39:"請注意以下幾點：",
    usdt40:"如果您選擇的是虛擬卡，請您提供正確的郵箱地址，我們將會發送至您的郵箱。",
    usdt41:"實體卡出貨週期約一週。請您提供正確的收貨地址與聯絡方式。",
    usdt42:"白天送貨時段:星期一至星期日,每日 09:00 至18:00",
    usdt43:"除你本人親自簽收之外,你的同住家屬或你指定的第三方簽收,或你指示承運人將產品置於指定地點,亦將視為你本人簽收,且產品已完成交付。 ",
    usdt44:"付款",
usdt45:"選擇地址",
usdt46:"確定",
usdt47:"載入中...",
usdt48:"請完善資料",
usdt49:"USDT正在付款中",
usdt50:"付款成功,正在產生訂單",
usdt51:"餘額不足",
usdt52:"支付失敗",
usdt53:"請選擇",
usdt54:"KAD正在付款中",
usdt55:"訂單生成成功",
usdt56:"訂單生成失敗",
usdt57:"國家選擇",
usdt58:"搜尋",
usdt59:"個人中心",
usdt60:"計算節點訂單",
usdt61:"儲值卡訂單",
usdt62:"實名認證",
usdt63:"待審核",
usdt64:"待認證",
usdt65:"審核失敗",
usdt66:"審核成功",
usdt67:"Telegram社群",
usdt68:"實名認證",
usdt69:"選擇國家和地區",
usdt70:"證件類型",
usdt71:"護照",
usdt72:"真實姓名",
usdt73:"請輸入真實姓名",
usdt74:"證件號碼",
usdt75:"請輸證件號碼",
usdt76:"證件照片（請選擇小於2M的圖片）",
usdt77:"請上傳人面像",
usdt78:"請上傳國徽面",
usdt79:"請上傳手持照",
usdt80:"請選擇國家和地區",
usdt81:"請填入真名",
usdt82:"請輸入證件號碼",
usdt83:"提交成功,等待審核",
usdt84:"立即申請",
usdt85:"查看信箱",
usdt86:"查看手機號碼",
}