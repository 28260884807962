import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4bdb76fc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "info"
};
const _hoisted_2 = {
  class: "line_item"
};
const _hoisted_3 = {
  class: "left"
};
const _hoisted_4 = {
  class: "left"
};
const _hoisted_5 = {
  class: "left"
};
const _hoisted_6 = {
  key: 0,
  class: "right",
  style: {
    "color": "#DF1B3B"
  }
};
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  key: 1
};
const _hoisted_9 = {
  key: 1,
  class: "right",
  style: {
    "color": "#DF1B3B"
  }
};
const _hoisted_10 = {
  key: 2,
  class: "right",
  style: {
    "color": "#DF1B3B"
  }
};
const _hoisted_11 = {
  class: "line_item"
};
const _hoisted_12 = {
  class: "left"
};
import { useI18n } from "vue-i18n";
import { user } from '@/api';
import lineTitle from '@/components/lineTitle.vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/user';
import { onMounted, onBeforeUnmount } from 'vue';
import { ref, watch } from 'vue';
export default {
  __name: 'info',
  setup(__props) {
    const {
      t
    } = useI18n();
    const router = useRouter();
    const store = useUserStore();
    let timer = null;
    let status = ref(3);
    let statusId = ref(0);
    watch(() => store.account, (newValue, oldValue) => {
      console.log("新的值:", newValue);
      console.log("旧的值:", oldValue);
      init();
    });
    watch(() => store.Lang, (newValue, oldValue) => {
      console.log("新的值:", newValue);
      console.log("旧的值:", oldValue);
      init();
    });
    function goCardOrder() {
      router.push('/CardOrder');
    }
    function goVerified() {
      console.log(status.value == 0 ? statusId.value == 0 : false);
      if (status.value == 2 || (status.value == 0 ? statusId.value != 0 : false)) return;
      router.push('/Verified');
    }
    onMounted(() => {
      status.value = store.statusCard;
      init();
      timer = setInterval(() => {
        init();
      }, 2000);
    });
    onBeforeUnmount(() => {
      clearInterval(timer);
    });
    function init() {
      user.ucauthinfo(store.account).then(res => {
        statusId.value = res.data.id;
        status.value = res.data.status;
        store.statusCard = res.data;
      });
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(lineTitle, {
        titletext: _unref(t)('lang.usdt59')
      }, null, 8, ["titletext"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('lang.usdt60')), 1)]), _createElementVNode("div", {
        class: "line_item",
        onClick: goCardOrder
      }, [_createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)('lang.usdt61')), 1)]), _createElementVNode("div", {
        class: "line_item",
        onClick: goVerified
      }, [_createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('lang.usdt62')), 1), _unref(status) == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_unref(statusId) != 0 ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(t)('lang.usdt63')), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(t)('lang.usdt64')), 1))])) : _unref(status) == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_unref(t)('lang.usdt65')), 1)) : _unref(status) == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_unref(t)('lang.usdt66')), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, _toDisplayString(_unref(t)('lang.usdt67')), 1)])]);
    };
  }
};