export const lang ={
    usdt1:"是什么让PayPal与众不同？",
    usdt2:"安全付款",
    usdt3:"无论买家还是卖家，都可享受行业领先的安全保护。",
    usdt4:"用户信赖",
    usdt5:"在全球数百万网店享受超快捷的支付体验。",
    usdt6:"收费透明",
    usdt7:"没有注册费或月费，卖家按交易额支付手续费。",
    usdt8:"USDT储值卡",
    usdt9:"基于KAD区块链去中心化加密技术;KAD链",
    usdt10:"数百万用户信赖选择",
    usdt11:"立即购买",
    usdt12:"计算节点",
    usdt13:"行业领先的节点维护技术;优越的硬件性能;",
    usdt14:"7*24h人工值守。",
    usdt15:"选择硬件",
    usdt16:"虚拟",
    usdt17:"实体",
    usdt18:"除你本人亲自签收之外,你的同住家属或你指定的第三方签收,或你指示承运人将产品置于指定地点,亦将视为你本人签收,且产品已完",
    usdt19:"确定",
    usdt20:"当前KAD价格为0,禁止购买",
    usdt21:"未实名认证，请先实名认证",
    usdt22:"实名认证待审核",
    usdt23:"实名认证审核失败",
    usdt24:"订单编号",
    usdt25:"类型",
    usdt26:"发货方式",
    usdt27:"操作",
    usdt28:"邮箱",
    usdt29:"邮递",
    usdt30:"结账",
    usdt31:"你的送货地址是哪里?",
    usdt32:"输入你的姓名和地址",
    usdt33:"姓氏",
    usdt34:"名字",
    usdt35:"详细地址",
    usdt36:"国家/地区",
    usdt37:"收件人手机号码",
    usdt38:"请输入邮箱",
    usdt39:"请注意以下几点：",
    usdt40:"如果您选择的是虚拟卡，请您提供正确的邮箱地址，我们将会发送至您的邮箱。",
    usdt41:"实体卡发货周期为一周左右。请您提供正确的收货地址与联系方式。",
    usdt42:"白天送货时段:星期一至星期日,每日 09:00 至18:00",
    usdt43:"除你本人亲自签收之外,你的同住家属或你指定的第三方签收,或你指示承运人将产品置于指定地点,亦将视为你本人签收,且产品已完成交付。",
    usdt44:"付款",
usdt45:"选择地址",
usdt46:"确定",
usdt47:"加载中...",
usdt48:"请完善资料",
usdt49:"USDT正在支付中",
usdt50:"支付成功,正在生成订单",
usdt51:"余额不足",
usdt52:"支付失败",
usdt53:"请选择",
usdt54:"KAD正在支付中",
usdt55:"订单生成成功",
usdt56:"订单生成失败",
usdt57:"国家选择",
usdt58:"搜索",
usdt59:"个人中心",
usdt60:"计算节点订单",
usdt61:"储值卡订单",
usdt62:"实名认证",
usdt63:"待审核",
usdt64:"待认证",
usdt65:"审核失败",
usdt66:"审核成功",
usdt67:"Telegram社群",
usdt68:"实名认证",
usdt69:"选择国家和地区",
usdt70:"证件类型",
usdt71:"护照",
usdt72:"真实姓名",
usdt73:"请输入真实姓名",
usdt74:"证件号码",
usdt75:"请输证件号码",
usdt76:"证件照片（请选择小于2M的图片）",
usdt77:"请上传人面像",
usdt78:"请上传国徽面",
usdt79:"请上传手持照",
usdt80:"请选择国家和地区",
usdt81:"请填写真实姓名",
usdt82:"请输入证件号码",
usdt83:"提交成功,等待审核",
usdt84:"立即申请",
usdt85:"查看邮箱",
usdt86:"查看手机号",
usdt87:"",
usdt88:"",
usdt89:"",
usdt90:"",
usdt91:"",
usdt92:"",
usdt93:"",
usdt94:"",
usdt95:"",
usdt96:"",
usdt97:"",
usdt98:"",
usdt99:"",
usdt100:"",

    
}