import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, isRef as _isRef, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6fc8763f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "search"
};
const _hoisted_2 = ["placeholder"];
const _hoisted_3 = ["onClick"];
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/store/user';
import lineTitle from '@/components/lineTitle.vue';
import { ref } from 'vue';
import countiylist from '@/utils/countiy';
export default {
  __name: 'countiy',
  setup(__props) {
    const {
      t
    } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useUserStore();
    let keyWord = ref('');
    let lists = ref(countiylist);
    function fuzzyQuery(list, keyWord) {
      console.log(list, keyWord);
      var arr = [];
      for (var i = 0; i < list.length; i++) {
        if (list[i].country_name_en.indexOf(keyWord) >= 0) {
          arr.push(list[i]);
        }
      }
      console.log(arr);
      lists.value = arr;
    }
    function backcheck(item) {
      store.countiystore = item.country_name_en;
      router.go(-1);
    }
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(lineTitle, {
        titletext: _unref(t)('lang.usdt57')
      }, null, 8, ["titletext"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_icon, {
        name: "search"
      }), _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(keyWord) ? keyWord.value = $event : keyWord = $event),
        onInput: _cache[1] || (_cache[1] = $event => fuzzyQuery(_unref(countiylist), _unref(keyWord))),
        placeholder: _unref(t)('lang.usdt58')
      }, null, 40, _hoisted_2), [[_vModelText, _unref(keyWord)]])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(lists), item => {
        return _openBlock(), _createElementBlock("div", {
          class: "countiybox",
          onClick: $event => backcheck(item)
        }, _toDisplayString(item.country_name_en), 9, _hoisted_3);
      }), 256))]);
    };
  }
};