import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, isRef as _isRef, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-51f3713d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "box"
};
const _hoisted_2 = {
  class: "tab title"
};
const _hoisted_3 = {
  class: "tab line"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  key: 1
};
const _hoisted_8 = ["onClick"];
const _hoisted_9 = ["onClick"];
const _hoisted_10 = {
  class: "popup"
};
const _hoisted_11 = {
  class: "popup-input"
};
import { useI18n } from "vue-i18n";
import { useUserStore } from '@/store/user';
import { user } from '@/api';
import lineTitle from '@/components/lineTitle.vue';
import { ref, onMounted, watch } from 'vue';
export default {
  __name: 'cardorder',
  setup(__props) {
    const {
      t
    } = useI18n();
    const store = useUserStore();
    let showEmail = ref(false);
    let Emaildata = ref('');
    let list = ref([]);
    function showemailFun(data) {
      Emaildata.value = data;
      showEmail.value = true;
    }
    onMounted(() => {
      init();
    });
    watch(() => store.account, (newValue, oldValue) => {
      console.log("新的值:", newValue);
      console.log("旧的值:", oldValue);
      init();
    });
    watch(() => store.Lang, (newValue, oldValue) => {
      console.log("新的值:", newValue);
      console.log("旧的值:", oldValue);
      init();
    });
    function init() {
      user.cardholdlist(store.account).then(res => {
        list.value = res.data;
      });
    }
    return (_ctx, _cache) => {
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", null, [_createVNode(lineTitle, {
        titletext: "储值卡订单"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, _toDisplayString(_unref(t)('lang.usdt24')), 1), _createElementVNode("div", null, _toDisplayString(_unref(t)('lang.usdt25')), 1), _createElementVNode("div", null, _toDisplayString(_unref(t)('lang.usdt26')), 1), _createElementVNode("div", null, _toDisplayString(_unref(t)('lang.usdt27')), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), item => {
        return _openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", null, _toDisplayString(item.card_id), 1), _createElementVNode("div", null, [_createTextVNode(_toDisplayString(item.name) + "( ", 1), item.type == 0 ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(t)('lang.usdt16')), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(t)('lang.usdt17')), 1)), _createTextVNode(")")]), item.type == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(t)('lang.usdt28')), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(t)('lang.usdt29')), 1)), item.type == 0 ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          style: {
            "color": "#DFAA3F"
          },
          onClick: $event => showemailFun(item.email)
        }, _toDisplayString(_unref(t)('lang.usdt85')), 9, _hoisted_8)) : item.type == 1 ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          style: {
            "color": "#DFAA3F"
          },
          onClick: $event => showemailFun(item.mobile)
        }, _toDisplayString(_unref(t)('lang.usdt86')), 9, _hoisted_9)) : _createCommentVNode("", true)]);
      }), 256))])]), _createVNode(_component_van_popup, {
        round: true,
        show: _unref(showEmail),
        "onUpdate:show": _cache[1] || (_cache[1] = $event => _isRef(showEmail) ? showEmail.value = $event : showEmail = $event),
        position: "bottom",
        style: {
          height: '30%'
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, _toDisplayString(_unref(Emaildata)), 1), _createElementVNode("div", {
          class: "button",
          onClick: _cache[0] || (_cache[0] = $event => _isRef(showEmail) ? showEmail.value = false : showEmail = false),
          style: {
            "width": "330px",
            "margin-top": "32px"
          }
        }, _toDisplayString(_unref(t)('lang.usdt19')), 1)])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};