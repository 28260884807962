import erc20 from '@/abi/erc20.json'
import Web3 from 'web3'

//授权
export default{
    transfer(myAddress, to, amount, currencyAddress) {
       
        console.log(myAddress, to, amount, currencyAddress);
        return new Promise(async function (resolve, reject) {
            let web3 = new Web3(window.web3.currentProvider);
            let crossingOkContractInstance = new web3.eth.Contract(erc20, currencyAddress);
          
            const gas = await crossingOkContractInstance.methods.transfer(to, web3.utils.toWei(String(amount),'ether')).estimateGas({ from: myAddress });
            let hash = ''
            crossingOkContractInstance.methods.transfer(to, web3.utils.toWei(String(amount),'ether')).send({ from: myAddress, gas: gas })
                .on('transactionHash', res => {
                    resolve(res)
                }).on('confirmation', res => {
                    
                }).on('error', res => {
                    reject(res)
                })
        })
    }
}
 


