import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6b6cb937"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "checkbox"
};
import { ref } from 'vue';
export default {
  __name: 'check',
  props: {
    lefText: {
      type: String
    },
    righText: {
      type: String
    }
  },
  emits: ["checkFun"],
  setup(__props, {
    emit
  }) {
    const props = __props;
    let checkdata = ref(1);
    function childcheckFun(index) {
      checkdata.value = index;
      emit('checkFun', index);
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = $event => childcheckFun(1)),
        class: _normalizeClass(_unref(checkdata) == 1 ? 'left box active' : 'left box')
      }, _toDisplayString(__props.lefText), 3), _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = $event => childcheckFun(2)),
        class: _normalizeClass(_unref(checkdata) == 2 ? 'right box active' : 'right box')
      }, _toDisplayString(__props.righText), 3)]);
    };
  }
};