import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "main"
};
import { useRouter } from 'vue-router';
import Header from './components/Header.vue';
import { ref } from 'vue';
import { onMounted, nextTick, onBeforeMount, watch } from 'vue';
import { useUserStore } from '@/store/user';
import { user } from '@/api/index';
import Web3 from "web3";
const bian = "https://bscscan.com/";
export default {
  __name: 'App',
  setup(__props) {
    const router = useRouter();
    const store = useUserStore();
    const account = ref('');
    watch(() => store.orderdata, (newValue, oldValue) => {
      console.log('store.orderdata', store.orderdata);
      if (JSON.stringify(store.orderdata) == "{}") {
        router.push('/');
      }
    });
    onMounted(() => {});
    onBeforeMount(async () => {
      // if (navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
      //     getUserMedia({ video: true, audio: true }); // 调用用户媒体设备，访问摄像头、录音
      //    } else {
      //      alert("你的浏览器不支持访问用户媒体设备");
      //    }

      store.setWebProvider();
      console.log('store', store.account);
      // await getaccount()
      if (!localStorage.getItem('Lang')) {
        localStorage.setItem('Lang', 'en-US');
      }
      sessionStorage.setItem('url', bian);
      setInterval(() => {
        store.setWebProvider();
      }, 6000);
    });
    const getaccount = async () => {
      web3.eth.requestAccounts().then(res => {
        let account1 = account.value;
        account.value = res[0];
        console.log(account.value);
        if (account1 != account.value) {
          let data = {
            address: account.value
          };
          user.register(data).then(res => {
            console.log(res);
          });
        }
        if (account1 != account.value || store.account == '' || store.account == undefined) {
          store.account = account.value;
          console.log('222222222', store.account);
        }
      });
      const aaa = await web3.eth.getGasPrice();
      console.log(aaa);
    };
    function getUserMedia(constrains) {
      if (navigator.mediaDevices.getUserMedia) {
        // 最新标准API
        navigator.mediaDevices.getUserMedia(constrains).then(stream => {
          this.success(stream);
        }).catch(err => {
          this.error(err);
        });
      } else if (navigator.webkitGetUserMedia) {
        // webkit内核浏览器
        navigator.webkitGetUserMedia(constrains).then(stream => {
          this.success(stream);
        }).catch(err => {
          this.error(err);
        });
      } else if (navigator.mozGetUserMedia) {
        // Firefox浏览器
        navigator.mozGetUserMedia(constrains).then(stream => {
          this.success(stream);
        }).catch(err => {
          this.error(err);
        });
      } else if (navigator.getUserMedia) {
        // 旧版API
        navigator.getUserMedia(constrains).then(stream => {
          this.success(stream);
        }).catch(err => {
          this.error(err);
        });
      }
    }
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(Header, {
        style: {
          "z-index": "99999"
        },
        account: account.value
      }, null, 8, ["account"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view)])], 64);
    };
  }
};