import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {createI18n} from 'vue-i18n';
import './utils/rem.js';
import vant from 'vant';
import "vant/lib/index.css";
import  '@/assets/ttf/iconfont.css';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import {createPinia} from 'pinia'
const store = createPinia()
const i18n = createI18n({
    locale: 'en-US', //切换语言
    legacy:false,
    messages: {
      'zh-CN': require('../src/lang/zh.js'),
      'en-US': require('../src/lang/en.js'),
      'zh-HK': require('../src/lang/tc.js'),
    },
  });
  const app =createApp(App)
  
  store.use(piniaPluginPersistedstate)
app.use(i18n).use(router).use(vant).use(store).mount('#app')
